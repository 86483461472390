<script>
    import determineBuildingImage from '$components/common/determineBuildingImage';
    import SearchDropdown from '$components/SearchDropdown.svelte';
    import ReviewExcerpt from '$components/homepage/ReviewExcerpt.svelte';
    import BuildingImage from '$components/BackgroundImage.svelte';
    import SEO from '$components/SEO.svelte';

    export let data;
    let { reviewScores, topBuildings } = data;
    $: ({ reviewScores, topBuildings } = data);
</script>

<SEO />

<div class='w-full'>
    <section id='hero' class='bg-blue-500 w-full rounded-2xl pt-10 pb-1 px-5 md:px-10 text-white'>
        <h3 class='text-2xl font-semibold text-center'>
            Search resident reviews for over 2600+ Toronto condo buildings!
        </h3>
        <div class='mt-10 m-auto w-6/6 md:w-3/6'>
            <SearchDropdown />
        </div>
        <div class='mt-5 m-auto w-full gap-5 md:px-[100px] py-0 md:py-5'>
            <div class='flex flex-col sm:flex-row justify-between'>
                <div class='text-center flex flex-row sm:flex-col items-center w-full md:w-[150px] m-auto md:m-0'>
                    <div class='aspect-square mr-5 md:mr-0 my-3 md:my-5'>
                        <img class='w-[50px] md:w-[80px]' src='/homepage/value-prop-1.png' alt='An icon depicting a review' />
                    </div>
                    Read authentic condo reviews
                </div>
                <div class='text-center flex flex-row sm:flex-col items-center w-full md:w-[150px] m-auto md:m-0'>
                    <div class='aspect-square mr-5 md:mr-0 my-3 md:my-5'>
                        <img class='w-[50px] md:w-[80px]' src='/homepage/value-prop-2.png' alt='An icon depicting a pencil on paper' />
                    </div>
                    Add your own review
                </div>
                <div class='text-center flex flex-row sm:flex-col items-center w-full md:w-[150px] m-auto md:m-0'>
                    <div class='aspect-square mr-5 md:mr-0 my-3 md:my-5'>
                        <img class='w-[50px] md:w-[80px]' src='/homepage/value-prop-3.png' alt='An icon depicting a moving truck' />
                    </div>
                    Find your ideal condo building
                </div>
            </div>
        </div>
    </section>
    <section class='mt-8'>
        <h3 class='font-heading-token text-2xl font-semibold mb-3'>
            Top Buildings
        </h3>
        <div class='grid grid-cols-2 md:grid-cols-4 gap-4 items-start'>
            {#each topBuildings as building}
                <a href='/buildings/{building.localities.slug}/{building.sub_localities.slug}-n{building.sub_localities.id}/{building.slug}'>
                    <div class='max-w-full'>
                        <div class='h-auto max-w-full'>
                            <BuildingImage src={determineBuildingImage(building).url} />
                        </div>
                        <span class='mt-2 text-lg block text-primary-700'>{building.name}</span>
                        <span class='text-xs text-gray-500 leading-none my-0.5 block'>
                            {building.address}
                        </span>
                        <span class='text-xs text-gray-500 block'>
                            {building.neighbourhood}, {building.sub_localities.name}, Toronto, ON
                        </span>
                    </div>
                </a>
            {/each}
        </div>
        <a href='/buildings/toronto'>
            <p class='font-heading-token text-right text-lg font-bold hover:underline underline-offset-4'>
                See all buildings >
            </p>
        </a>
    </section>
    <section class='mt-8'>
        <h3 class='font-heading-token text-2xl font-semibold mb-3'>
            Latest Reviews
        </h3>
        <div class='flex flex-col md:flex-row md:text-center'>
            {#each reviewScores as reviewScore}
                <ReviewExcerpt {reviewScore} />
            {/each}
        </div>
    </section>
    <section class='mt-8 text-center'>
        <p class='text-2xl font-bold text-center'>Add your review &mdash; share your condo experience today:</p>
        <a href='/add-review'
           class='btn btn-lg rounded-lg text-white bg-gradient-to-br variant-gradient-primary-tertiary'>Add a Review</a>
    </section>
    <section class='flex flex-col md:flex-row mt-8'>
        <div id='find-reviews'
             class='w-full md:w-1/2 bg-gradient-to-b from-green-400 to-blue-500 mr-8 flex items-center px-10 rounded-xl'>
            <p class='font-heading-token text-4xl font-bold text-white text-left align-middle justify-center items-center opacity-100'>
                Find Reviews <br class='hidden md:inline-block' /> by Region
            </p>
        </div>
        <div class='w-full md:w-1/2 mt-4 md:mt-0'>
            <div class='grid grid-cols-2 gap-4'>
                <a href='/buildings/toronto/downtown-n1'>
                    <div class='region'>
                        Downtown Toronto
                    </div>
                </a>
                <a href='/buildings/toronto/north-york-n8'>
                    <div class='region'>
                        North York
                    </div>
                </a>
                <a href='/buildings/toronto/scarborough-n6'>
                    <div class='region'>
                        Scarborough
                    </div>
                </a>
                <a href='/buildings/toronto/midtown-n4'>
                    <div class='region'>
                        Midtown
                    </div>
                </a>
                <a href='/buildings/toronto/etobicoke-n2'>
                    <div class='region'>
                        Etobicoke
                    </div>
                </a>
                <a href='/buildings/toronto'>
                    <div class='rounded-lg bg-white border-neutral-300 border-2 w-full mr-4 text-center px-4 py-3'>
                        See All Regions
                    </div>
                </a>
            </div>
        </div>
    </section>
</div>

<style>
    p {
        @apply my-5;
    }

    .region {
        @apply rounded-lg bg-neutral-100 w-full mr-4 text-center px-4 py-3 border-[1px] border-neutral-100 hover:border-primary-200;
    }

    #find-reviews {
        border-radius: 3px;
        background: linear-gradient(180deg, rgba(161, 192, 141, 0.85), rgba(133, 198, 204, 0.85)), url($lib/images/old-toronto-map.webp);
    }

    #hero {
        background: linear-gradient(170deg, #6690cedd, #7cb9a9dd), url($lib/images/skyline.jpg);
        background-size: cover;
        background-position-y: center;
    }
</style>
