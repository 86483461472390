<script>
    import AggregatedReviewScore from "$components/AggregatedReviewScore.svelte";

    export let reviewScore;
    export let truncateLength = 120;

    let { score, reviews, reviewer_name } = reviewScore;
    $: ({ score, reviews, reviewer_name } = reviewScore);
    let originalCommentary;
    let commentary = '';

    $: {
        if (score >= 4.0) {
            originalCommentary = reviews.review_rating_commentaries?.[0].best_parts;
        } else {
            originalCommentary = reviews.review_rating_commentaries?.[0].worst_parts;
        }
        commentary = originalCommentary.substring(0, truncateLength)
        commentary += originalCommentary.length > truncateLength ? '...' : '';
    }
</script>

<a class='flex flex-col items-center justify-around rounded-lg bg-neutral-100 w-full mr-4 px-3 md:px-3 my-3 md:my-0 last:mr-0 border-[1px] border-neutral-100 hover:border-primary-200'
    href='/buildings/{reviewScore.reviews.buildings.localities.slug}/{reviewScore.reviews.buildings.sub_localities.slug}-n{reviewScore.reviews.buildings.sub_localities.id}/{reviewScore.reviews.buildings.slug}?rid={reviewScore.review_uuid}'>
    <p class='mt-3 mb-1 h-1/3'>
        <AggregatedReviewScore reviewScore={score} size="medium" />
    </p>
    <p class='middle text-gray-500 italic text-xs my-1 h-1/3'>&ldquo;{commentary}&rdquo;</p>
    <p class='text-xs text-primary-500 mt-5 mb-3 h-1/3'>
        <strong>{reviewer_name}</strong><span>&nbsp;on&nbsp;</span><strong>{reviews.buildings.name}, {reviews.buildings.address}</strong>
    </p>
</a>

<style>
    .middle {
        @apply flex flex-col items-center justify-center;
    }
</style>